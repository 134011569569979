import React from 'react';

const Footer = () => {
    return (
        <footer className='footer'>
            <span className='footer__text'>© Все права защищены.</span>
            <span className='footer__text'>Сверчкова Екатерина @ya_sverchkova</span>
        </footer>
    )
}

export default Footer;
